import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
	Flex,
} from 'reakit'

import { Container, Sidebar, Body, Footer, } from '../../../components/layouts'

import {
	CustomMessage
} from '../../../components/ui'

import {
	refreshToken,
	getMessages
} from '../../../factory'

import Layout from '../../../components/layout'

import theme from '../../../theme/content'

const {customMessages} = theme

class IndexPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			bodySize: 0,
		}

		this.getBodyWidth = this.getBodyWidth.bind(this)
	}

	componentDidMount() {
		this.props.refreshToken().then((res)=> {
			this.props.getMessages(res)
		})
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.getBodyWidth.bind(this))
	}


	updateComponent() {
	}

	getBodyWidth() {
		this.setState({
			bodySize: (window.innerWidth - 280)
		})
	}

	render() {
		const {messages} = this.props
		return (
			<Layout>
				<Container>
					<Sidebar content={theme.adminSidebar} />
					<Body>
						<div style={{minHeight: 'calc(100vh - 200px)'}}>
							<Flex row minHeight={'8em'} justifyContent="space-between" width="100%" marginBottom="1em">
								<CustomMessage
									title="Welcome Text Message"
									id={customMessages.welcomeText}
									messages={messages}
								/>
							</Flex>
							<Flex row minHeight={'8em'} justifyContent="space-between" width="100%" marginBottom="1em">
								<CustomMessage isEmail
									title="Welcome Email Message"
									id={customMessages.welcomeEmail}
									messages={messages}
								/>
							</Flex>

							<Flex row minHeight={'8em'} justifyContent="space-between" width="100%" marginBottom="1em">
								<CustomMessage isEmail
									title="Thank You Message"
									id={customMessages.thankYouEmail}
									messages={messages}
								/>
							</Flex>
						</div>
						<Flex flex="0 0" alignItems="start" marginLeft="-1em" width="calc(100% + 2em)" row>
							<Footer />
						</Flex>
						<Flex />
					</Body>
				</Container>
			</Layout>
		)
	}
}

IndexPage.propTypes = {
	token: PropTypes.string,
	getMessages: PropTypes.func,
	refreshToken: PropTypes.func,
	messages: PropTypes.array
}

const mapStateToProps = (state) => {
	return {
		token: state.authState.accessToken,
		messages: state.oneClickState.messages,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getMessages: (token) => dispatch(getMessages(token)),
		refreshToken: () => dispatch(refreshToken())
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(IndexPage)
